
.button-content {
  position: absolute;
  top: 0;
  left: 0;
  max-width: 100%;
}

.btn-transparent {
  max-width: 25%;
  margin: 0 !important;
  border: none;
  transition: var(--transition-btn);
  filter: drop-shadow(5px 5px 0 var(--shadow-brown));
}

.btn-check:active+.btn-transparent {
  transform: var(--btn-active)
}

.btn-transparent:hover {
  transform: var(--btn-hover);
  filter: drop-shadow(10px 10px 0 var(--shadow-brown));
}

/* Extra small <576px */
@media (max-width: 576px) {
  .btn-transparent {
    max-width: 50%;
    padding: 0.5rem;
  }
}

/* Small ≥ 576px */
@media (min-width: 576px) {
  .btn-transparent {
    max-width: 33%;
    padding: 0.5rem;
  }
}

/* Medium > 768px */
/* normally it is (min-width: 768px)  */
@media (min-width: 769px) {
  .btn-transparent {
    max-width: 25%;
    padding: 1rem;
  }
}

/* Large ≥992px */
@media (min-width: 992px) {
  .btn-transparent {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

/* Extra large ≥1200px */
@media (min-width: 1200px) {
  .btn-transparent {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
