@font-face {
  font-family: 'CustomFont';
  src: url('assets/fonts/fredoka-one-regular.ttf') format('truetype');

  /* The available types are: "woff", "woff2", "truetype", "opentype", "embedded-opentype", and "svg". */
}

/* COLORS */
:root {
  --light-color: #f8f9fa;
  --dark-color: #0d1117;

  --brown: #a66a53;

  /* --dark-brown: #613930; */
  --shadow-blue: #4a90bd;
  --shadow-brown: #9c776f;
}

/* TRANSITIONS */
:root {
  --var-tr-speed: 0.15s;
  --var-tr-animation: ease-in-out;
  --tr-background-color: background-color var(--var-tr-speed) var(--var-tr-animation);
  --tr-background-image: background-image var(--var-tr-speed) var(--var-tr-animation);
  --tr-border-color: border-color var(--var-tr-speed) var(--var-tr-animation);
  --tr-color: color var(--var-tr-speed) var(--var-tr-animation);
  --tr-filter: filter var(--var-tr-speed) var(--var-tr-animation);
  --tr-transform: transform var(--var-tr-speed) var(--var-tr-animation);

  --transition-btn: var(--tr-color), var(--tr-background-color), var(--tr-border-color), var(--tr-transform), var(--tr-filter), var(--tr-background-image);
}

/* ANIMATIONS */
:root {
  --btn-active: scale(0.95);
  --btn-hover: scale(1.05);
}

body {
  font-family: 'CustomFont';
  background-image: url('assets/images/background.png');
  background-size: cover;
  color: var(--light-color);
}

/* BOOTSTRAP OVERRIDE */
.btn,
.btn:focus,
.btn:active {
  box-shadow: none !important;
}

.container {
  max-width: 82%;
}

.mw-65 {
  max-width: 65% !important;
}

.mw-30 {
  max-width: 30% !important;
}

/* **************************************** */
/* **************************************** */

/* BG ANIMATION */

/* body {
  background: linear-gradient(-45deg, #ee7752, #e73c7e, #23a6d5, #23d5ab);
  background-size: 400% 400%;
  animation: gradient 15s ease infinite;
}

@keyframes gradient {
  0% {
      background-position: 0% 50%;
  }
  50% {
      background-position: 100% 50%;
  }
  100% {
      background-position: 0% 50%;
  }
} */

/* @keyframes bg-scrolling {
  0% {
    background-position: 100vw 100vh;
  }
} */
