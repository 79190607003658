:root {
  --purple: #a970ff;
  --purple-2: #9147ff;
  --shadow-purple: #5c16c5;
}

.btn-purple {
  color: var(--dark-color);
  background-color: var(--purple);
  filter: drop-shadow(5px 5px 0 var(--shadow-purple));
  transition: var(--transition-btn)
}

.btn-purple:hover {
  transform: var(--btn-hover);
}

.btn-purple:active {
  transform: var(--btn-active);
}

/* svg */
.color-purple {
  fill: var(--purple);
}

.color-white {
  fill: var(--dark-color);
}
