/* logout button */
.logout-button {
  filter: drop-shadow(3px 3px 0 var(--shadow-blue));
  transition: var(--transition-btn);
  font-size: 1.25rem; /* == fs-5 */
}

.logout-button:hover {
  transform: var(--btn-hover);
}

.logout-button:active {
  transform: var(--btn-active);
}

@media (max-width: 768px) {
  .logout-button {
    font-size: 1rem; /* == fs-6 */
  }
}
