/* NORMAL */
.title {
  font-size: calc(1.375rem + 1.5vw); /* == fs-1 */
  text-shadow: 5px 5px 0 var(--shadow-blue);
}

.logo {
  width: 100px;
}

.logo-shadow {
  filter: drop-shadow(5px 5px 0 var(--shadow-blue));
}

/* LARGE */
.logo-lg {
  width: 80%;
}

.logo-shadow-lg {
  filter: drop-shadow(5px 5px 0 var(--shadow-blue));
}

/* Small < 768px */
@media (max-width: 768px) {
  .title {
    font-size: calc(1.275rem + .3vw); /* == fs-4 */
    text-shadow: 5px 5px 0 var(--shadow-blue);
  }

  .logo {
    width: 60px;
  }

  .logo-shadow {
    filter: drop-shadow(3px 3px 0 var(--shadow-blue));
  }
}
