.custom-tabs .custom-tab-button {
  background-image: url('../../assets/images/categories/RedUnselected.png');
  background-size: 100% 100%;
  color: var(--light-color);
  outline-style: none;
  filter: drop-shadow(5px 5px 0 var(--shadow-blue));
  transition: var(--transition-btn);
}

.custom-tabs .custom-tab-button:hover {
  background-image: url('../../assets/images/categories/RedHovered.png');
  transform: var(--btn-hover);
}

.custom-tabs .custom-tab-button:active {
  transform: var(--btn-active);
}

.custom-tabs .custom-tab-button.active {
  background-color: transparent;
  background-image: url('../../assets/images/categories/RedSelected.png');
}

@media (max-width: 768px){
  .nav-item {
    min-width: 33%;
    max-width: 33%;
  }
}
