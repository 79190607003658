.custom-tab-content {
  height: 60vh;
  background-image: url('../../assets/images/Pin.png'), url('../../assets/images/Pin.png'), url('../../assets/images/Socle.png');
  background-position: top 3% right 3%, bottom 5% left 2%, top left;
  background-size: 4%, 4%, 100% 100%;
  background-repeat: no-repeat, no-repeat, no-repeat;
  filter: drop-shadow(10px 10px 0 var(--shadow-blue));
}

/* Extra small <576px */
@media (max-width: 576px) {
  .custom-tab-content {
    padding: 1rem;
  }
}

/* Small ≥576px */
@media (min-width: 576px) {
  .custom-tab-content {
    padding: 1rem;
  }
}

/* Medium ≥768px */
@media (min-width: 768px) {
  .custom-tab-content {
    padding: 1rem;
  }
}

/* Large ≥992px */
@media (min-width: 992px) {
  .custom-tab-content {
    padding: 1.5rem;
  }
}

/* Extra large ≥1200px */
@media (min-width: 1200px) {
  .custom-tab-content {
    padding: 3.5rem 3rem;
  }
}